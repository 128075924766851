<template>
    <div class="modal history_details" v-if="modelValue">
        <div class="modal_container">
            <div class="modal_header border-bottom pt-0 pb-0">
                <h1>Habit Details 
                    <span>{{ moment(history.scheduled_at).format('ll') }}</span>
                    <label v-if="history.is_submitted && history.is_edited">Edited After Submission</label>
                    <label v-else-if="history.is_submitted && moment.duration(moment(history.updated_at).diff(history.scheduled_at)).asHours() >= 2">Submitted Late</label>
                    <label v-else-if="history.is_submitted">Submitted On Time</label>
                    <label v-else>Missed</label>
                </h1>
                <button class="close_btn left_out" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <div class="task_details">
                    <div class="quest_panel">
                        <div class="category_wpr" v-for="(category, index) of categories" :key="index">
                            <h3 class="sub_header">{{ category.name }}</h3>
                            <div class="task_info" v-for="(item, i) of category.habits" :key="i">
                                <h4><label>{{ i + 1 }}</label>{{ item.description }}</h4>
                                <div class="details">
                                    <p>{{ item.requirements ? item.requirements : 'No requirement available'}}</p>
                                    <div class="perform_activity" v-if="habitTracking.recurring_type == 2">
                                        <div class="activity_wpr">
                                            <div class="grp_wpr">
                                                <label class="yes" :class="{ active: parseInt(form[item.id].answer) === 1 }">
                                                    <div class="box_item"><i class="fas fa-thumbs-up mr-2"></i>Yes</div>
                                                </label>
                                                <label class="no" :class="{ active: parseInt(form[item.id].answer) === 0 }">
                                                    <div class="box_item"><i class="fas fa-thumbs-down mr-2"></i>No</div>
                                                </label>
                                            </div>
                                        </div>
                                        <!-- <ul class="days" v-if="habitTracking.recurring_type == 2">
                                            <li :class="{ active: parseInt(form[item.id].answer) === 1 }" title="Yes">Y</li>
                                            <li :class="{ active: parseInt(form[item.id].answer) === 0 }" title="No">N</li>
                                        </ul> -->
                                        
                                    </div>
                                    <div class="perform_activity" v-if="habitTracking.recurring_type == 3">
                                        <div class="days_wpr">
                                            <ul class="days">
                                                <li v-for="(wd, w) of weekdays" :key="w">
                                                    <label>
                                                        <div class="item_wpr">
                                                            <span class="weekdays">{{ wd.label.slice(0, 3) }}</span>
                                                            <span class="tick_box" :class="{ active: form[item.id].answer.includes(wd.value + 1) }"></span>
                                                        </div>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                        <!-- <ul class="days" v-if="habitTracking.recurring_type == 3">
                                            <li :class="{ active: form[item.id].answer.includes(wd.value + 1) }" :title="wd.title" v-for="(wd, w) of weekdays" :key="w">{{ wd.label }}</li>
                                        </ul> -->
                                    </div>
                                    <!-- <div class="activity_wpr">
                                        <span>Performed?</span>
                                        <div class="grp_wpr">
                                            <label class="active" :class="parseInt(form[item.id].answer) >= 0 || (habitTracking.recurring_type == 3 && form[item.id].answer && (form[item.id].answer.filter(w => w > 0)).length) ? 'yes' : 'no'">
                                                <div class="box_item"><i :class="`fas fa-thumbs-${parseInt(form[item.id].answer) >= 0 || (habitTracking.recurring_type == 3 && form[item.id].answer && (form[item.id].answer.filter(w => w > 0)).length) ? 'up' : 'down'} mr-2`"></i>{{ parseInt(form[item.id].answer) >= 0 || (habitTracking.recurring_type == 3 && form[item.id].answer && (form[item.id].answer.filter(w => w > 0)).length) ? 'Yes' : 'No'}}</div>
                                            </label>
                                        </div>
                                    </div> -->
                                    <div class="upload_image" v-show="(parseInt(form[item.id].answer) >= 0 || (habitTracking.recurring_type == 3 && form[item.id].answer && (form[item.id].answer.filter(w => w > 0)).length)) && form[item.id].file">
                                        <img v-if="form[item.id].file" :src="form[item.id].file" alt="">
                                    </div>
                                    <!-- <ul>
                                        <li class="setting_wpr">
                                            <span>Performed:</span>
                                            <div class="perform_info">
                                                <label>{{ parseInt(form[item.id].answer) >= 0 || (habitTracking.recurring_type == 3 && form[item.id].answer && (form[item.id].answer.filter(w => w > 0)).length) ? 'Yes' : 'No'}}</label>
                                            </div>
                                        </li>
                                        <li v-if="(parseInt(form[item.id].answer) >= 0 || (habitTracking.recurring_type == 3 && form[item.id].answer && (form[item.id].answer.filter(w => w > 0)).length)) && form[item.id].file">
                                            <span>Proof: {{ form[item.id].file ? '' : 'NA' }} </span>
                                            <img v-if="form[item.id].file" :src="form[item.id].file" alt="" class="mb-2">
                                        </li>
                                    </ul> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment'

    export default {
        name: 'Habit History Preview',

        data () {
            return {
                weekdays: [
                    { title: 'Monday', label: 'M', value: 1 },
                    { title: 'Tuesday', label: 'T', value: 2 },
                    { title: 'Wednesday', label: 'W', value: 3 },
                    { title: 'Thursday', label: 'T', value: 4 },
                    { title: 'Friday', label: 'F', value: 5 },
                    { title: 'Saturday', label: 'S', value: 6 },
                    { title: 'Sunday', label: 'S', value: 0 },
                ],
                moment,
                form: JSON.parse(JSON.stringify(this.answers)),
            };
        },

        props: {
            modelValue: Boolean,
            categories: Object,
            history: Object,
            habitTracking: Object,
            answers: Object,
        },

        emits: ['update:modelValue'],

        watch: {
            answers (answers) {
                const vm = this;

                vm.form = JSON.parse(JSON.stringify(answers));
            },

            history (history) {
                const vm = this;

                if (history.answers.length) {
                    history.answers.forEach((answer) => {
                        if (vm.form[answer.habit_tracking_habit_id]) {
                            vm.form[answer.habit_tracking_habit_id].answer = answer.answer;
                            vm.form[answer.habit_tracking_habit_id].has_proof = answer.has_proof;
                            vm.form[answer.habit_tracking_habit_id].file = answer.file;
                        }
                    });
                } else {
                    Object.keys(vm.form).forEach((habit_tracking_habit_id) => {
                        if (vm.form[habit_tracking_habit_id]) {
                            vm.form[habit_tracking_habit_id].answer = '';
                            vm.form[habit_tracking_habit_id].has_proof = 0;
                            vm.form[habit_tracking_habit_id].file = '';
                        }
                    });
                }
            },
        },

        methods: {
            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },
        },
    }
</script>

<style scoped>
    .history_details .modal_header h1{
        flex-wrap: wrap;
    }
    .history_details .modal_header h1 label{
        width: 100%;
        font-size: 13px;
        line-height: 20px;
        font-weight: 400;
        padding-top: 5px;
        color: #5a5a5a;
    }
    .activity_details .details ul li label:not(.checkbox) {
        background: #2f7eed;
    }

    .activity_details .details ul li .perform_info label:not(.checkbox) {
        background: #2f7eed;
    }
    .task_details .task_info h4{
        color: var(--var-element-text-color);
    }
    .task_details .task_info h4 label {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        border: 1px solid var(--var-element-bg-color);
        color: var(--var-element-bg-color);
        font-size: 9px;
        line-height: 18px;
        font-weight: 500;
        display: flex;
        justify-content: center;
        flex-shrink: 0;
        margin: 2px 12px 0 0;
    }
    .task_details .task_info .details p {
        padding: 0 0 5px 30px;
    }
    .task_details .perform_activity{
        margin-top: 10px;
    }
    .activity_wpr {
        padding: 5px 0 0;
        width: 100%;
    }
    .grp_wpr {
        display: flex;
        gap: 8px;
        padding: 5px 0 15px 30px;
    }

    .grp_wpr label .box_item{
        font-size: 11px;
        line-height: 15px;
        border-radius: 4px;
        padding: 7px 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
    }
    .grp_wpr label .box_item i{
        font-size: 15px;
    }
    .grp_wpr label .box_item {
        border: 1px solid #e9e9e9;
        background: #f5f5f5;
        color: #999;
    }
    .grp_wpr label.yes.active .box_item {
        border: 1px solid #b2eabf;
        background: #effff2;
        color: #23993e;
    }
    .grp_wpr label.no.active .box_item {
        border: 1px solid #ffb4b4;
        background: #ffecec;
        color: #eb1414;
    }
    /* .grp_wpr label.active .box_item, .grp_wpr label input[type="radio"]:checked ~ .box_item {
        background: #2f7eed;
        border: 1px solid #2f7eed;
    } */
</style>
